import Vue from 'vue'
import VueRouter from 'vue-router'
import Integritetspolicy from '../views/Integritetspolicy.vue'
import StartPage from '../views/StartPage.vue'
import Startsida from '../views/Startsida.vue'
import UIUXDesigner from '../views/UI-UX-Designer.vue'
import FinansiellKommunikation from '../views/Finansiell-Kommunikation.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Startsida
    },
    {
        path: '/en',
        name: 'home-en',
        component: StartPage
    },
    {
        path: '/integritetspolicy',
        name: 'integritetspolicy',
        component: Integritetspolicy
    },
    {
        path: '/ux-ui-designer',
        name: 'ux-ui-designer',
        component: UIUXDesigner
    },
    {
        path: '/finansiell-kommunikation',
        name: 'finansiell-kommunikation',
        component: FinansiellKommunikation
    },
    {   path: '/:pathMatch(.*)*', 
        redirect: '/'
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: "is-visible",
    linkExactActiveClass: "is-active",
    routes
})

router.beforeEach((to, from, next) => {
    //if (from.name == null) {
    //    $('body').addClass('is-initial-load')
    //} else {
    //    $('body').removeClass('is-initial-load')
    //}
    
    next();
})

export default router
