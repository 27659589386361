<template>
    <div id="app">
        <topbar />

        <div class="wh-overlay"></div>
        
        <transition
            name="fade"
            mode="out-in"
            @beforeLeave="beforeLeave"
            @enter="enter"
            @afterEnter="afterEnter"
        >
            <router-view ref="currentView" />
        </transition>
    </div>
</template>

<script>

import Topbar from './components/layout/Topbar';
//import Sitefooter from './components/layout/Sitefooter'

export default {
    name: 'app',

    components: {
        Topbar,
    },

    mixins: [

    ],

    data () {
        return {
        }
    },

    created () {

    },

    mounted () {
        this.checkWhichLogoToUse()

        //$(window).on('click', function (event) {
        //    if (event.target.className == 'cky-btn cky-btn-accept') {
        //        event.preventDefault();

        //        bootstrap().then(() => {
        //            console.log('gtag bootstraped');
        //            localStorage.setItem('whAcceptedCookies', true);
        //        })
        //    }
        //})

        //window.addEventListener('click', (event) => {
        //    if (event.target.className == 'cky-btn cky-btn-accept') {
        //        event.preventDefault();

        //        bootstrap().then(() => {
        //            console.log('gtag bootstraped');
        //        })
        //    }
        //});
    },

    methods: {

        beforeLeave () {
            //console.log("Before leave", view);
            //this.isViewVisible = false

            //this.closeMenu()
            //this.removeViewOffset()
            //console.log('before', view);
            $('.wh-overlay').addClass('is-leaving')
            $('.wh-siteheader-language').addClass('is-leaving')
            $('.wh-siteheader-logo-item').addClass('is-hidden')
        },
        
        enter () {
            //console.log('enter', view);

            //window.scrollTo(0, 0)

            window.setTimeout(() => {
                $('.wh-overlay').addClass('is-entering')
            }, 350)
        },

        afterEnter () {
            //console.log("After enter", view, this.$refs.currentView);
            //console.log('after', view);

            window.scrollTo(0, 0)

            window.setTimeout(() => {
                $('.wh-overlay, .wh-siteheader-language')
                    .removeClass('is-leaving')
                    .removeClass('is-entering')
                
                this.checkWhichLogoToUse()
            }, 350)

            //this.$refs.currentView.viewIsLoaded()
            //this.isStoryItemInView()
        },

        checkWhichLogoToUse () {
            $('.wh-siteheader-logo-item').addClass('is-hidden')

            if (this.$route.name == 'home' || this.$route.name == 'home-en') {
                $('.wh-siteheader-logo-item.is-light').removeClass('is-hidden')
            }
            
            if (this.$route.name == 'integritetspolicy' || this.$route.name == 'ux-ui-designer') {
                $('.wh-siteheader-logo-item.is-dark').removeClass('is-hidden')
            }
            
            if (this.$route.name == 'finansiell-kommunikation') {
                $('.wh-siteheader-logo-item.is-bigger').removeClass('is-hidden')
            }
        }
    }
}

</script>