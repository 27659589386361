<template>
    <div class="wh-hero" data-transition-color="is-purple">
        <div class="wh-hero-inner">
            <header class="wh-hero-header">
                <h1 
                    class="wh-hero-header-heading"
                    :class="{'is-inverted': isInverted, 'is-pink': $route.name == 'finansiell-kommunikation'}"
                    v-if="$route.name != 'finansiell-kommunikation'"
                >
                    <span 
                        v-if="$route.name != 'finansiell-kommunikation'"
                        class="wh-hero-header-heading-word is-one"
                        :class="{'is-inverted': isInverted}"
                    >Well&nbsp;</span> 
                    <span 
                        v-if="$route.name != 'finansiell-kommunikation'"
                        class="wh-hero-header-heading-word is-two"
                        :class="{'is-inverted': isInverted}"
                    > hello,</span><br v-if="$route.name != 'finansiell-kommunikation'">
                    <span 
                        class="wh-hero-header-heading-word is-three"
                        :class="{'is-inverted': isInverted, 'is-pink': $route.name == 'finansiell-kommunikation'}"
                    >
                        {{ replacementWord != null ? replacementWord : wordOfTheDay }}
                    </span>
                </h1>
                <h1 
                    class="wh-hero-header-heading is-pink is-smaller"
                    v-if="$route.name == 'finansiell-kommunikation'"
                >
                    <span 
                        class="wh-hero-header-heading-word is-pink is-one"
                    >
                        Vi stärker ditt
                    </span> 
                    <span 
                        class="wh-hero-header-heading-word is-pink is-two"
                    > 
                        finansiella
                    </span><br>
                    <span 
                        class="wh-hero-header-heading-word is-pink is-three"
                    >
                        varumärke
                    </span>
                </h1>


                <!--<a href="#contact" class="wh-hero-header-button wh-button">
                    Say hi!
                </a>-->
            </header>

            <div 
                class="wh-hero-text" 
                v-if="$route.name != 'finansiell-kommunikation'"
            >
                <p class="wh-hero-text-item is-lead is-medium" v-if="language == 'sv'">
                    Well Hello är en design- och kommunikationsbyrå i Växjö. Dagligen skapar vi digitala upplevelser, formulerar pricksäkert content och trollar med pixlar. Allt för att stärka ditt varumärke och driva din business framåt. Let's go!
                </p>
                <p class="wh-hero-text-item is-lead is-medium" v-if="language == 'en'">
                    Well Hello is a design and communication agency based in Växjö, and we're here to make your brand shine! We work our magic to craft digital experiences, formulate content with pinpoint accuracy and finesse pixels to strengthen and drive your business forward. Let's go!
                </p>
            </div>

            <!--<router-link 
                to="/ux-ui-designer" 
                class="wh-hero-spinning-link"
                v-if="$route.name == 'home' && language == 'sv'"
            >
                <img 
                    src="../assets/static/images/ux.svg" 
                    alt="" 
                    class="wh-hero-spinning-link-icon"
                >
                <img 
                    src="../assets/static/images/sunglasses.svg" 
                    alt="" 
                    class="wh-hero-spinning-link-emoji"
                >
            </router-link>-->

            <!--<router-link 
                to="/ux-ui-designer" 
                class="wh-hero-spinning-link"
                v-if="$route.name == 'home-en' && language == 'en'"
            >
                <img 
                    src="../assets/static/images/ux-eng.svg" 
                    alt="" 
                    class="wh-hero-spinning-link-icon"
                >
                <img 
                    src="../assets/static/images/sunglasses.png" 
                    alt="" 
                    class="wh-hero-spinning-link-emoji"
                >
            </router-link>-->
        </div>
    </div>
</template>

<script>


export default {
    name: 'hero-component',

    props: [
        'language',
        'replacementWord',
        'isInverted'
    ],

    data () {
        return {
            wordOfTheDay: ' ',
            words: {
                1: 'Sunshine',
                2: 'Gorgeous',
                3: 'Awesome',
                4: 'Sweet Pea',
                5: 'Teacup',
                6: 'Friend',
                7: 'Beautiful',
                8: 'Adorable',
                9: 'Peaches',
                10: 'Handsome',
                11: 'Cutie Pie',
                12: 'Fantastic',
                13: 'Munchkin',
                14: 'Smoochie',
                15: 'Firecracker',
                16: 'Amigo',
                17: 'Hotshot',
                18: 'Superhero',
                19: 'Sparky',
                20: 'Cheery',
                21: 'Curious',
                22: 'Jelly Bean',
                23: 'Fabulous',
                24: 'Goofy',
                25: 'Nugget',
                26: 'Bubbles',
                27: 'Chipmunk',
                28: 'Doughnut',
                29: 'Muffin',
                30: 'Honey Bee',
                31: 'Buttercup',
            },
            specialDays: {
                '12-24': 'SANTA CLAUSE',
                '14-2': 'Love, Valentine',
                '24-12': 'Santa, Rudolph',
                '13-12': 'Lusse Lelle',
                '25-3':	'Waffle lover',
                '31-10': 'Scary friend',
                '6-6': 'Swede',
                //'31-12': 'Twenty twenty four',
                'firstThursdayInMarch': 'Smalander'
            }
        }
    },

    created () {
        this.generateWordOfTheDay()
    },

    mounted () {
        //var textWrapper = $('.wh-hero-header-heading-word');
        //textWrapper.html(
        //    textWrapper[0].textContent.replace(
        //        /\S/g, 
        //        "<span class='wh-hero-header-heading-word-letter'>$&</span>"
        //    )
        //)

        //window.setTimeout(() => {
        //    anime.timeline({loop: false})
        //        .add({
        //            targets: '.wh-hero-header-heading-word .wh-hero-header-heading-word-letter',
        //            translateY: [-100,0],
        //            easing: "easeOutExpo",
        //            opacity: 1,
        //            duration: 1400,
        //            delay: (el, i) => 30 * i
        //        })
        //        //.add({
        //        //    targets: '.wh-hero-header-heading-word',
        //        //    opacity: 1,
        //        //    duration: 1000,
        //        //    easing: "easeOutExpo",
        //        //    delay: 1000
        //        //});
        //}, 600)
    },

    methods: {

        firstThursdayInMarch (currentDate, currentDateFormatted) {
            const firstThur = new Date(currentDate.getFullYear(), 2, 1,15,0,0,0); // first of the month

            for (let i = 1; i < 7; i++) {
                if (firstThur.getDay() === 4) {
                    break
                }
                
                firstThur.setDate(firstThur.getDate()+1)
            }

            let firstThursdayInMarchDate = `${firstThur.getFullYear()}-${firstThur.getMonth() + 1}-${firstThur.getDate()}`

            if (currentDateFormatted == firstThursdayInMarchDate) {
                return true
            }

            return false
        },

        generateWordOfTheDay () {
            let currentDate = new Date()
            let currentYear = currentDate.getFullYear()
            let currentMonth = currentDate.getMonth() + 1
            let currentDay = currentDate.getDate()
            let fullDate = `${currentYear}-${currentMonth}-${currentDay}`
            let monthAndDayDate = `${currentMonth}-${currentDay}`

            if (this.firstThursdayInMarch(currentDate, fullDate)) {
                return this.wordOfTheDay = this.specialDays['firstThursdayInMarch'];
            }

            if (this.specialDays[monthAndDayDate] != null) {
                return this.wordOfTheDay = this.specialDays[monthAndDayDate]
            }

            this.wordOfTheDay = this.words[currentDay]
        }
    }
}

</script>