<template>
    <div class="wh-view is-pink">
        <div class="wh-integritypolicy">
            <div class="wh-integritypolicy-inner">

                <h3 class="wh-integritypolicy-heading is-purple">
                    Integritetspolicy
                </h3>

                <p class="wh-integritypolicy-text-heading is-medium is-lead is-purple">
                    Well Hello Design & Communication AB (nedan “Well Hello”), org. nr. 556935-9861, med adress Bäckgatan 12, 352 31 Växjö, Sverige är ett företag etablerat i Sverige, och vi följer tillämplig dataskyddslagstiftning i Sverige. I vår integritetspolicy förklarar vi vilka typer av personuppgifter vi kan komma att behandla och i vilket syfte.
                </p>
                
                <p class="wh-integritypolicy-text-title is-bold is-large is-purple">
                    Allmänt
                </p>
                <p class="wh-integritypolicy-text is-medium is-purple">
                    Integritetspolicyn avser behandling av personuppgifter för vilka Well Hello är personuppgiftsansvarig och tillämpas när du delar dina personuppgifter direkt eller indirekt med Well Hello. Den gäller dig som kommer i kontakt med oss i din yrkesroll, som anställd eller som arbetssökande. Well Hello samlar in vissa personuppgifter och dessa behandlas på olika sätt. Dessa personuppgifter kommer inte att vidarebefordras, säljas, bytas eller delas med andra företag, organisationer eller privatpersoner utan ditt samtycke.
                </p>
                
                <p class="wh-integritypolicy-text-title is-bold is-large is-purple">
                    Personuppgifter vi samlar in
                </p>
                <p class="wh-integritypolicy-text is-medium is-purple">
                    Well Hello får i första hand personuppgifter direkt och avser uppgifter som kan hänföras till dig. Exempel på personuppgifter som vi samlar in är ditt namn, telefonnummer, e-post, fysisk adress, kontonummer eller personnummer.
                    <br>
                    <br>
                    Vi kan komma att behandla personuppgifter i samband med:
                    <br>
                    <ul class="wh-integritypolicy-text-list">
                        <li>Nykundsbearbetning och offertförfrågan</li>
                        <li>När du blir kund</li>
                        <li>Under pågående kunduppdrag</li>
                        <li>När du kontaktar oss vi e-post, telefon, webbplats och sociala media</li>
                        <li>När du blir leverantör</li>
                        <li>Uppdrag där vi hanterar dina kunders uppgifter</li>
                        <li>Vid anställning, praktik eller arbetsansökan</li>
                    </ul>
                </p>


                <p class="wh-integritypolicy-text-title is-bold is-large is-purple">
                    Syfte med behandlingen
                </p>
                
                <p class="wh-integritypolicy-text is-medium is-purple">
                    Syftet med behandlingen och den legala grunden för behandlingen av respektive personuppgiftskategorier är i huvudsak:
                    <br>
                    <ul class="wh-integritypolicy-text-list">
                        <li>
                            Som kund eller ny kund hos Well Hello behandlas dina uppgifter i den mån det är nödvändigt för att administrera kundförhållandet och för att uppfylla våra åtaganden gentemot dig och myndigheter. Behandlingen grundas på det lagkrav, avtal eller det kundförhållande som uppstår vid exempelvis en beställning som kunden gör hos Well Hello.
                        </li>
                        <li>
                            Som ny kund eller under nykundsbearbetning behandlas dina uppgifter i den mån det är nödvändigt för kontakta dig och presentera affärsförslag. Behandlingen grundas på intresseavvägning eller det kundförhållande som uppstår vid till exempel ett möte eller presentation.
                        </li>
                        <li>
                            Som leverantör eller partner behandlas dina uppgifter i den mån det är nödvändigt för att administrera och hantera samarbetet. Behandlingen grundas på det lagkrav, avtal eller det leverantörsförhållande som uppstår vid exempelvis en beställning till en leverantör.
                        </li>
                        <li>
                            Som anställd behandlas dina uppgifter för att kunna fullgöra anställningsavtalet, betala ut lön, administrera försäkringar och för att göra det möjligt att intressenter till Well Hello kan kontakta våra medarbetare. Behandlingen grundas på det avtal som finns samt lagkrav.
                        </li>
                        <li>
                            Som arbetssökande behandlas dina uppgifter under rekryteringsperioden och vid spontantansökningar som kommer in till Well Hello. Behandlingen grundas på samtycke.
                        </li>
                        <li>
                            Som kund till Well Hellos kund behandlas dina uppgifter för att kunna informera eller samla in uppgifter för kunds räkning. Behandlingen kan då grundas på samtycke, avtal eller intresseavvägning.
                        </li>
                    </ul>
                </p>


                <p class="wh-integritypolicy-text-title is-bold is-large is-purple">
                    Förvaring och skydd av uppgifter
                </p>
                
                <p class="wh-integritypolicy-text is-medium is-purple">
                    Personuppgifter lagras så länge det krävs för att uppfylla syftet (inkluderat syftet att uppfylla de rättsliga skyldigheter som åligger Well Hello). Överflödiga uppgifter raderas efter att ändamålet med uppgifterna uppfyllts. Endast personer hos Well Hello som behöver behandla personuppgifterna för ändamålen ovan har tillgång till uppgifterna. För att skydda dina personuppgifter mot obehörig åtkomst krävs behörighet och inloggning och kan endast ske med hjälp av användar-ID och lösenord. De anställda hos Well Hello är skyldiga att följa Well Hellos regler för informations- och IT-säkerhet samt denna Integritetspolicy.
                </p>

                <p class="wh-integritypolicy-text-title is-bold is-large is-purple">
                    Informationsdelning med tredjepart och överföring till tredje land
                </p>
                
                <p class="wh-integritypolicy-text is-medium is-purple">
                    Vi kan komma att dela personuppgifter med våra personuppgiftsbiträden av de syften som angetts ovan. Uppgifterna kan komma att lämnas ut till myndigheter i enlighet med vad som föreskrivs i lag, till eventuella samarbetspartners som levererar stödtjänster till företaget, såsom IT-tjänster och andra företagstjänster, på det sätt och i den omfattning som krävs för att företaget ska kunna fullfölja sina åtaganden.
                </p>


                <p class="wh-integritypolicy-text-title is-bold is-large is-purple">
                    Om Cookies
                </p>
                
                <p class="wh-integritypolicy-text is-medium is-purple">
                    Vår webbplats wellhello.se använder tjänster som via cookies samlar in information om användaren med syfte att:
                    <ul class="wh-integritypolicy-text-list">
                        <li>Förbättra prestandan på webbplatsen</li>
                        <li>Analysera användarbeteende</li>
                        <li>Förbättra användarupplevelsen</li>
                        <li>Optimera innehållet i annonser och marknadsaktiviteter</li>
                    </ul>
                    Uppgifterna som samlas kan handla om teknisk information såsom vilket operativsystem, webbläsare och enhet du använder, samt information om hur du interagerar med innehållet på wellhello.se och de kanaler du använde för att hitta till vår webbplats.
                </p>

                <p class="wh-integritypolicy-text-title is-bold is-large is-purple">
                    Samtycke till användning av cookies
                </p>
                <p class="wh-integritypolicy-text is-medium is-purple">
                    Enligt lag (2003:389) om elektronisk kommunikation får cookies endast användas med användarens samtycke. Om du inte samtycker till användandet av cookies när du besöker wellhello.se kan du antingen inaktivera användningen av cookies i din webbläsare, eller specificera ditt samtycke i den cookie-ruta som visas när du besöker vår webbplats.
                    <br><br>
                    Om du nekar samtycke till alla eller vissa cookies som används på wellhello.se, så kan detta medföra att vissa tjänster och funktioner inte kommer att fungera som avsett.
                </p>

                <p class="wh-integritypolicy-text-title is-bold is-large is-purple">
                    Dina rättigheter
                </p>
                
                <p class="wh-integritypolicy-text is-medium is-purple">
                    Well Hello är personuppgiftsansvarig för behandlingen av dina personuppgifter. Detta innebär att vi ansvarar för att dina personuppgifter behandlas korrekt och i enlighet med tillämplig lag. Du har rätt att återkalla ditt samtycke avseende behandling av personuppgifter som grundar sig på ditt samtycke. Notera att återkallelsen av samtycket inte påverkar rätten att behandla personuppgifter i enlighet med tillämpliga lagar.
                    <br><br>
                    Du har rätt att erhålla information om vi behandlar dina personuppgifter, i vilket syfte de behandlas, om sådana personuppgifter överförs till tredje land och, om tillämpligt, vilka som mottagit dina personuppgifter. För att uppdatera, rätta eller radera uppgifter vi har om dig, nyttja dina rättigheter eller för ytterligare information om företagets hantering av personuppgifter kontakta <a class="wh-integritypolicy-text is-link is-purple" href="mailto:daniel@wellhello.se">daniel@wellhello.se</a>
                </p>
                
                <p class="wh-integritypolicy-text-title is-bold is-large is-purple">
                    Personuppgiftsansvarig
                </p>
                
                <p class="wh-integritypolicy-text is-medium is-purple">
                    Ansvarig för behandlingen av personuppgifter är:
                    <br>
                    Well Hello Design & Communication AB, 556935-9861
                    <br>
                    Bäckgatan 12, 352 31 Växjö
                    <br><br>
                    Dessa allmänna villkor gäller från och med den 1 maj 2020.
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'integritetspolicy',

    metaInfo: {
        title: 'Well Hello – Integritetspolicy',
        meta: [
            {
                name: 'description',
                content: 'Integritetspolicyn avser behandling av personuppgifter för vilka Well Hello är personuppgiftsansvarig och tillämpas när du delar dina personuppgifter direkt eller indirekt med Well Hello.',
            },
            {
                property: 'og:title',
                content: 'Well Hello – Integritetspolicy',
            },
            {
                property: 'og:description',
                content: "Integritetspolicyn avser behandling av personuppgifter för vilka Well Hello är personuppgiftsansvarig och tillämpas när du delar dina personuppgifter direkt eller indirekt med Well Hello.",
            },
            {
                property: 'og:url',
                content: 'https://wellhello.se/integritetspolicy',
            },
            {
                property: 'og:locale',
                content: 'sv_SE',
            },
            {
                property: 'og:image',
                content: 'https://wellhello.se/wellhello-og.png',
            },
            {
                name: 'twitter:image',
                content: 'https://wellhello.se/wellhello-og.png',
            },
        ],
        link: [
            {
                rel: 'canonical',
                href: 'https://wellhello.se/integritetspolicy'
            },
        ],
        htmlAttrs: {
            lang: 'sv-SE'
        }
    },

    data () {
        return {
            title: 'Integritetspolcy',
        }
    },

    mounted () {
    },

    methods: {
    }
}

</script>
