<template>
    <div class="wh-siteheader is-topbar">
        <div class="wh-siteheader-inner">
            <nav class="wh-siteheader-nav">
                <router-link class="wh-siteheader-logo" to="/" :class="{'is-bigger': $route.name == 'finansiell-kommunikation'}">
                    <img 
                        src="../../assets/static/images/wh_logo_purple.svg" 
                        alt="" 
                        class="wh-siteheader-logo-item is-hidden is-dark"
                        v-if="$route.name != 'finansiell-kommunikation'" 
                    >
                    <img 
                        src="../../assets/static/images/wh_animated_logo.svg" 
                        alt="" 
                        class="wh-siteheader-logo-item is-hidden is-light"
                    >
                    <img 
                        src="../../assets/static/images/wellhello-beachfront-logo.svg" 
                        alt="" 
                        class="wh-siteheader-logo-item is-hidden is-bigger"
                        v-if="$route.name == 'finansiell-kommunikation'" 
                    >
                    <!-- <video 
                        class="wh-siteheader-logo-video is-hidden"
                        muted 
                        autoplay 
                        loop 
                        playsinline
                    >
                        <source src="../../assets/static/video/movie-webm.webm" type="video/webm">
                        <source src="../../assets/static/video/wh-animation-3.mov" type="video/mp4;codecs=hvc1">
                    </video> -->
                </router-link>
            </nav>
            
            <router-link 
                to="/"
                class="wh-siteheader-close-button"
                v-if="$route.name == 'integritetspolicy' || $route.name == 'ux-ui-designer' || $route.name == 'finansiell-kommunikation'" 
            >
                <!--<div 
                    class="wh-siteheader-close-button"
                >
                </div>-->
            </router-link>
            
            <!--<router-link to="/">
                <div 
                    class="wh-siteheader-close-button"
                    v-if="$route.name == 'integritetspolicy'" 
                >
                </div>
            </router-link>-->

            <div 
                class="wh-siteheader-language" 
                :class="{'is-hidden': $route.name == 'integritetspolicy' || $route.name == 'ux-ui-designer' || $route.name == 'finansiell-kommunikation'}"
            >
                <router-link to="/" 
                    class="wh-siteheader-language-link is-bold" 
                    :class="{'is-dark': $route.name == 'integritetspolicy'}"
                >
                    SE
                </router-link>

                <router-link to="/en" 
                    class="wh-siteheader-language-link is-bold" 
                    :class="{'is-dark': $route.name == 'integritetspolicy'}"
                >
                    EN
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'topbar',

    data () {
        return {
            
        }
    },

    methods: {
        
    }
}

</script>