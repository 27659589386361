<template>
    <div class="wh-view">
        <hero-component :language="'sv'" />

        <div class="wh-areas" data-transition-color="is-purple">
            <div class="wh-areas-inner">
                <div class="wh-areas-items-tablet">
                    <div 
                        class="wh-areas-tablet-item"
                        @click.prevent="showCreative"
                        :class="{'is-active': creativeIsShowing == true}"
                    >
                        <h3 class="wh-areas-tablet-item-heading">
                            Creative
                        </h3>
                        <i class="wh-areas-tablet-item-icon material-icons">
                            add
                        </i>
                        <div 
                            class="wh-areas-tablet-item-wrapper"
                        >
                            <div 
                                class="wh-areas-tablet-item-wrapper-image is-creative"
                                :class="{'is-active': creativeIsShowing == true}"
                            ></div>
                            <div 
                                class="wh-areas-tablet-item-wrapper-content"
                                :class="{'is-active': creativeIsShowing == true}"
                            >
                                <p class="wh-areas-tablet-item-wrapper-content-text is-medium is-large">
                                    Ta plats, gör avtryck och visa världen vem du är
                                </p>
                                <p class="wh-areas-tablet-item-wrapper-content-list">
                                    Idé- och koncept, Design, Reklam, Marknadsföring, Identitet, Copy, Content, Illustration, Video, Animering, SoMe
                                </p>
                            </div>
                        </div>
                    </div>
                    <div 
                        class="wh-areas-tablet-item"
                        @click.prevent="showTech"
                        :class="{'is-active': techIsShowing == true}"
                    >
                        <h3 class="wh-areas-tablet-item-heading">
                            Tech
                        </h3>
                        <i class="wh-areas-tablet-item-icon material-icons">
                            add
                        </i>
                        <div 
                            class="wh-areas-tablet-item-wrapper"
                        >
                            <div 
                                class="wh-areas-tablet-item-wrapper-image is-tech"
                                :class="{'is-active': techIsShowing == true}"
                            ></div>
                            <div 
                                class="wh-areas-tablet-item-wrapper-content"
                                :class="{'is-active': techIsShowing == true}"
                            >
                                <p class="wh-areas-tablet-item-wrapper-content-text is-medium is-large">
                                    Bygg en upplevelse, engagera och konvertera
                                </p>
                                <p class="wh-areas-tablet-item-wrapper-content-list">
                                    UX, UI, Webbutveckling, Systemutveckling, Utbildning, Appar, Automatisering, SEO, SEM, Research
                                </p>
                            </div>
                        </div>
                    </div>
                    <div 
                        class="wh-areas-tablet-item"
                        @click.prevent="showBusiness"
                        :class="{'is-active': businessIsShowing == true}"
                    >
                        <h3 class="wh-areas-tablet-item-heading">
                            Business
                        </h3>
                        <i class="wh-areas-tablet-item-icon material-icons">
                            add
                        </i>
                        <div 
                            class="wh-areas-tablet-item-wrapper"
                        >
                            <div 
                                class="wh-areas-tablet-item-wrapper-image is-business"
                                :class="{'is-active': businessIsShowing == true}"
                            ></div>
                            <div 
                                class="wh-areas-tablet-item-wrapper-content"
                                :class="{'is-active': businessIsShowing == true}"
                            >
                                <p class="wh-areas-tablet-item-wrapper-content-text is-medium is-large">
                                    Ställ in siktet och följ vägen mot framgång
                                </p>
                                <p class="wh-areas-tablet-item-wrapper-content-list">
                                    Strategi, Interim, Medieplanering, Förändringsarbete, Utbildning, Rådgivning, Projektledning, Analys
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div class="wh-areas-items">
                    <div class="wh-areas-item">
                        <div class="wh-areas-item-image is-creative"></div>
                        <header class="wh-areas-item-header">
                            <h3 class="wh-areas-item-header-heading">
                                Creative
                            </h3>
                            <p class="wh-areas-item-header-text is-medium is-large">
                                Ta plats, gör avtryck och visa världen vem du är
                            </p>
                            <p class="wh-areas-item-header-list is-lead">
                                Idé- och koncept, Design, Reklam, Marknadsföring, Identitet, Copy, Content, Illustration, Video, Animering, SoMe
                            </p>
                        </header>
                    </div>
                    <div class="wh-areas-item">
                        <div class="wh-areas-item-image is-tech"></div>
                        <header class="wh-areas-item-header">
                            <h3 class="wh-areas-item-header-heading">
                                Tech
                            </h3>
                            <p class="wh-areas-item-header-text is-medium is-large">
                                Bygg en upplevelse, engagera och konvertera
                            </p>
                            <p class="wh-areas-item-header-list is-lead">
                                UX, UI, Webbutveckling, Systemutveckling, Utbildning, Appar, Automatisering, SEO, SEM, Research
                            </p>
                        </header>
                    </div>
                    <div class="wh-areas-item">
                        <div class="wh-areas-item-image is-business"></div>
                        <header class="wh-areas-item-header">
                            <h3 class="wh-areas-item-header-heading">
                                Business
                            </h3>
                            <p class="wh-areas-item-header-text is-medium is-large">
                                Ställ in siktet och följ vägen mot framgång
                            </p>
                            <p class="wh-areas-item-header-list is-lead">
                                Strategi, Interim, Medieplanering, Förändringsarbete, Utbildning, Rådgivning, Projektledning, Analys
                            </p>
                        </header>
                    </div>
                </div>
            </div>
        </div>

        <div class="wh-clients" data-transition-color="is-white">
            <div class="wh-clients-inner">
                <div class="wh-clients-header">
                    <div class="wh-clients-title">
                        <h2 class="wh-clients-title-item">
                            PEOPLE WE<br>
                            WORK WITH
                        </h2>
                    </div>
                    <div class="wh-clients-text">
                        <p class="wh-clients-text-item is-medium is-large">
                            Våra kunder är allt från startups till större börsnoterade bolag inom olika branscher. Det som förenar dem är deras engagemang och vågade visioner. I vissa fall arbetar vi i projektform och ibland tillför vi spetskompetens i form av interimslösningar. Huvudsaken är att vi skapar långsiktiga resultat tillsammans.
                        </p>
                    </div>
                </div>
                <div class="wh-clients-items">
                    <a 
                        href="https://colivia.com" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/colivia_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://greatsecurity.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/greatsecurity_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://hrmsoftware.se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/hrm_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.augment.se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/augment_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://wexiodisk.com" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/wexiodisk_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.nabsolutions.se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/nab_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://app-properties.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/app_properties_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://eurobatteryminerals.com" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/ebm_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.skanska.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/skanska_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://obos.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/obos_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.abena.se/sv-se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/abena_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.bolsius.com/se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/bolsius_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.castellum.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/castellum_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://el-vis.com" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/elinfo_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.stralfors.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/postnord_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://briox.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/briox_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://sun4energy.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/s4e_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                </div>
            </div>
        </div>

        <div class="wh-team" id="contact" data-transition-color="is-purple">
            <div class="wh-team-header">
                <div class="wh-team-title">
                    <h2 class="wh-team-title-item">
                        Don't be a<br>
                        stranger
                    </h2>
                </div>
                <div class="wh-team-text">
                    <p class="wh-team-text-item is-medium is-large">
                        Vi är nyfikna på vem du är. Hör av dig till någon av oss eller skicka ett mejl till <a href="mailto:work@wellhello.se">work@wellhello.se</a> så tar vi en första dejt!  
                    </p>
                </div>
            </div>
            
            <!--<a href="mailto:business@wellhello.se" class="wh-team-contact">
                <p class="wh-team-contact-text is-lead is-purple is-bold">
                    Släng iväg ett mejl
                </p>
            </a>-->

            <div class="wh-team-items">
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-daniel">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                GODFATHER OF STRATEGY & DESIGN
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Daniel är en passionerad designer och rådgivare inom varumärken, design och produktutveckling. Han är en ivrig läsare och lyssnare på poddar och ser till att ständigt ta pulsen på branschen. Hans insikter och erfarenheter gör att han ofta anlitas som strategiskt bollplank i våra kunders styrelser och ledningsgrupper.
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Daniel Liljeblad
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Godfather of strategy & design
                        </h5>
                        <a href="tel:0709500277" class="wh-team-item-header-link is-medium">
                            070 950 02 77
                        </a>
                        <a href="mailto:daniel@wellhello.se" class="wh-team-item-header-link is-medium">
                            daniel@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-martina">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                DESIGN & COMMUNICATION GODDESS
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Martina driver våra kunders affärer framåt med hjälp av effektiv kommunikation och inspirerande design. Hon bjussar gärna på sin kompetens, både på kontoret men också hos våra kunder som anlitar henne på ledningsnivå. En oslagbar medarbetare som höjder nivån i varje projekt hon tar sig an.
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Martina Bengtsson
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Design & communication goddess
                        </h5>
                        <a href="tel:0708337897" class="wh-team-item-header-link is-medium">
                            070 833 78 97
                        </a>
                        <a href="mailto:martina@wellhello.se" class="wh-team-item-header-link is-medium">
                            martina@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-anna">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                QUEEN OF CONTENT & PROJECT MANAGEMENT
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Ansvar, noggrannhet och koll på läget är några av Annas paradgrenar. Hon planerar, strukturerar och säkerställer att våra projekt håller tidsramar och budgetar. Anna är dessutom grym på både design och textproduktion. För många av våra börsnoterade bolag jobbar hon med att visualisera deras finansiella rapporter. En multitalang, helt enkelt!
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Anna Bergström
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Queen of content & project management
                        </h5>
                        <a href="tel:0762203800" class="wh-team-item-header-link is-medium">
                            076 220 38 00
                        </a>
                        <a href="mailto:anna@wellhello.se" class="wh-team-item-header-link is-medium">
                            anna@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-emil">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                TECH OVERLORD
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Emil är ansvarig för våra techuppdrag och nördar gärna ner sig i nya innovationer – kunskap som är värdefull när digitala produkter ska utvecklas, appar ska byggas och när olika system ska prata med varandra. Kommer du förbi kontoret är det troligtvis Emil som öppnar och får dig att känna dig som hemma.
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Emil Makowski
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Tech overlord
                        </h5>
                        <a href="tel:0763172530" class="wh-team-item-header-link is-medium">
                            076 317 25 30
                        </a>
                        <a href="mailto:emil@wellhello.se" class="wh-team-item-header-link is-medium">
                            emil@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-marcus">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                FRONTEND PIXELNINJA
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                I vårt tech-team hittar du Marcus, som är en talangfull frontend utvecklare med passion för att skapa och underhålla webbplatser och appar. Marcus nyfikenhet och öga för detaljer är en stor tillgång när vi utvecklar och utvärderar våra projekt. Han är alltid beredd att ta sig an utmaningar och triggas av problemlösning. 
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Marcus Andersson
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Frontend pixelninja
                        </h5>
                        <a href="tel:0761184857" class="wh-team-item-header-link is-medium">
                            076 118 48 57
                        </a>
                        <a href="mailto:marcus@wellhello.se" class="wh-team-item-header-link is-medium">
                            marcus@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-axel">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                DIGITAL MARKETING PROPHET
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Rutinerad, mångsidig och pragmatisk. Tre ord som beskriver Axel. Flera av hans kunder har hängt med i decennier och vi förstår varför. Han är ett proffs på att skapa digitala kampanjer som driver engagemang och levererar resultat. Då och då briljerar han lite extra och bjussar på något magiskt hembakat.
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Axel Nilsson
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            DIGITAL MARKETING PROPHET
                        </h5>
                        <a href="tel:0702674058" class="wh-team-item-header-link is-medium">
                            070 267 40 58
                        </a>
                        <a href="mailto:axel@wellhello.se" class="wh-team-item-header-link is-medium">
                            axel@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-tea">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                Word magician
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Behöver du en korrekt och välskriven text är det med största sannolikhet Tea som hjälper dig. Oavsett om det är content för sociala medier, säljande webbcopy eller nyhetsartiklar ser hon till att fånga känslan i ord och få din text att flyga. Några kärnfulla ord eller ett längre reportage - du väljer!
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Tea Juteström
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Word magician
                        </h5>
                        <a href="tel:0705089678" class="wh-team-item-header-link is-medium">
                            070 508 96 78
                        </a>
                        <a href="mailto:tea@wellhello.se" class="wh-team-item-header-link is-medium">
                            tea@wellhello.se
                        </a>
                    </header>
                </div>
            </div>
        </div>

        <sitefooter language="sv" />
    </div>
</template>

<script>
import heroComponent from '../components/hero-component.vue';
import Sitefooter from '../components/layout/Sitefooter';
import { pageComposables } from '../composables/page-composables';


export default {
    name: 'startsida',

    metaInfo: {
        title: 'Well Hello – Design- och kommunikationsbyrå i Växjö',
        meta: [
            {
                name: 'description',
                content: "Vi arbetar dagligen med att ta fram värdeskapande kommunikation och upplevelser som hjälper våra kunder att stärka sitt varumärke och nå sina affärsmål. Hos oss får du hjälp av experter inom strategi, design, content och tech. Let's go",
            },
            {
                property: 'og:title',
                content: 'Well Hello – Design- och kommunikationsbyrå i Växjö',
            },
            {
                property: 'og:description',
                content: "Vi arbetar dagligen med att ta fram värdeskapande kommunikation och upplevelser som hjälper våra kunder att stärka sitt varumärke och nå sina affärsmål. Hos oss får du hjälp av experter inom strategi, design, content och tech. Let's go",
            },
            {
                property: 'og:url',
                content: 'https://wellhello.se',
            },
            {
                property: 'og:locale',
                content: 'sv_SE',
            },
            {
                property: 'og:image',
                content: 'https://wellhello.se/wellhello-og.png',
            },
            {
                name: 'twitter:image',
                content: 'https://wellhello.se/wellhello-og.png',
            },
        ],
        link: [
            {
                rel: 'canonical',
                href: 'https://wellhello.se'
            },
        ],
        htmlAttrs: {
            lang: 'sv-SE'
        }
    },

    components: {
        heroComponent,
        Sitefooter
    },

    data () {
        return {
            title: 'Startsida',
            creativeIsShowing: false,
            techIsShowing: false,
            businessIsShowing: false,
        }
    },

    mounted () {
        const { transitionModules } = pageComposables();
        transitionModules(
            '.wh-hero, .wh-areas, .wh-clients, .wh-team, .wh-sitefooter',
            {
                //'.wh-hero, .wh-hero-header, .wh-hero-text, .wh-areas'
                '.wh-hero': null,
                '.wh-hero-header': null,
                '.wh-hero-header-heading': null,
                '.wh-hero-text-item': {
                    delay: 0
                },
                '.wh-hero-spinning-link': null,
                '.wh-hero-spinning-link-icon': null,
                '.wh-hero-spinning-link-emoji': null,
                '.wh-areas': null,
                '.wh-sitefooter-item-logo': null
            }
        )

        $('.wh-team-item-image-toggle').on('click', function (event) {
            event.preventDefault()

            if ($(this).parent().hasClass('has-splash')) {
                $(this).parent().toggleClass('has-info-open')
                setTimeout(() => {
                    $(this).parent().toggleClass('has-splash')
                }, 50);   
            } else {
                $(this).parent().toggleClass('has-splash')
                setTimeout(() => {
                    $(this).parent().toggleClass('has-info-open')
                }, 300)
            }
        })
    },

    methods: {
        showCreative () {
            this.creativeIsShowing = !this.creativeIsShowing;
        },

        showTech () {
            this.techIsShowing = !this.techIsShowing;
        },

        showBusiness () {
            this.businessIsShowing = !this.businessIsShowing;
        }
    }
}

</script>
