import $ from 'jquery';
export const pageComposables = () => {
  function calculateVisibility(modules) {
    modules.each(function (i, element) {
      var elementItem = $(element);
      var moduleStart = elementItem.offset().top;
      var offsetTrigger = $(window).width() > 768 ? 0.7 : 0.9;
      // var scrollOffset = (window.innerHeight + $(document).scrollTop()) - window.innerHeight;
      var scrollOffset = window.innerHeight + $(document).scrollTop() - window.innerHeight * offsetTrigger;
      var moduleEnd = moduleStart + elementItem.outerHeight();

      if (scrollOffset > moduleStart && scrollOffset < moduleEnd) {
        // elementItem.addClass('is-visible');
        // //console.log(elementItem.attr('data-fade-color'));
        // self.fadeMovementBackground(
        //     elementItem.attr('data-fade-color')
        // )
        if (elementItem.attr('data-transition-color')) {
          // elementItem
          //    .addClass('is-visible')
          if ($('.wh-body').hasClass(elementItem.attr('data-transition-color')) == false) {
            $('.wh-body, .wh-view')
              .removeClass('is-purple')
              .removeClass('is-blue')
              .removeClass('is-white')
              .addClass(elementItem.attr('data-transition-color'));
          }
        } else {
          $('.wh-body').removeClass('is-purple').removeClass('is-blue').removeClass('is-white');
          // elementItem
          //     .removeClass('is-visible')
        }
      }
      // else if ((scrollOffset + (window.innerHeight * 0.8)) < moduleStart && (scrollOffset) < moduleEnd) {
      //     elementItem
      //         .removeClass('is-visible')
      // }
    });
  }

  function moduleOffset(elements) {
    for (var element in elements) {
      var elementItem = $(element);

      if (elementItem.length == 0) {
        continue;
      }

      var offset = elementItem.offset().top;
      var windowHeight = window.innerHeight + $(document).scrollTop() - 20;

      if (windowHeight > offset) {
        if (elements[element] != null) {
          window.setTimeout(
            function (data) {
              data.element.addClass('is-visible');
            }.bind(null, { element: elementItem, index: element }),
            elements[element].delay
          );
        } else {
          elementItem.addClass('is-visible');
        }
      }
    }
  }

  function transitionModules(moduleClassNames, detailedAnimationClasses) {
    const modules = $(moduleClassNames);
    //const subModules = $(detailedAnimationClasses);

    moduleOffset(detailedAnimationClasses);
    calculateVisibility(modules);

    $(window).scroll(() => {
      moduleOffset(detailedAnimationClasses);
      calculateVisibility(modules);
    });
  }
  return {
    transitionModules,
  };
};
