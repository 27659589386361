<template>
    <div class="wh-view">
        <hero-component :language="'en'" />

        <div class="wh-areas" data-transition-color="is-purple">
            <div class="wh-areas-inner">
                <div class="wh-areas-items-tablet">
                    <div 
                        class="wh-areas-tablet-item"
                        @click.prevent="showCreative"
                        :class="{'is-active': creativeIsShowing == true}"
                    >
                        <h3 class="wh-areas-tablet-item-heading">
                            Creative
                        </h3>
                        <i class="wh-areas-tablet-item-icon material-icons">
                            add
                        </i>
                        <div 
                            class="wh-areas-tablet-item-wrapper"
                        >
                            <div 
                                class="wh-areas-tablet-item-wrapper-image is-creative"
                                :class="{'is-active': creativeIsShowing == true}"
                            ></div>
                            <div 
                                class="wh-areas-tablet-item-wrapper-content"
                                :class="{'is-active': creativeIsShowing == true}"
                            >
                                <p class="wh-areas-tablet-item-wrapper-content-text is-medium is-large">
                                    Leave your imprint and tell your story to the world
                                </p>
                                <p class="wh-areas-tablet-item-wrapper-content-list is-large">
                                    Idea and koncept, Design, Advertising, Marketing, Identity, Copy, Content, Illustration, Video, Animation, SoMe
                                </p>
                            </div>
                        </div>
                    </div>
                    <div 
                        class="wh-areas-tablet-item"
                        @click.prevent="showTech"
                        :class="{'is-active': techIsShowing == true}"
                    >
                        <h3 class="wh-areas-tablet-item-heading">
                            Tech
                        </h3>
                        <i class="wh-areas-tablet-item-icon material-icons">
                            add
                        </i>
                        <div 
                            class="wh-areas-tablet-item-wrapper"
                        >
                            <div 
                                class="wh-areas-tablet-item-wrapper-image is-tech"
                                :class="{'is-active': techIsShowing == true}"
                            ></div>
                            <div 
                                class="wh-areas-tablet-item-wrapper-content"
                                :class="{'is-active': techIsShowing == true}"
                            >
                                <p class="wh-areas-tablet-item-wrapper-content-text is-medium is-large">
                                    Create an experience, engage, and convert
                                </p>
                                <p class="wh-areas-tablet-item-wrapper-content-list is-large">
                                    UX, UI, Web development, Software development, Education, Apps, Automation, SEO, SEM, Research
                                </p>
                            </div>
                        </div>
                    </div>
                    <div 
                        class="wh-areas-tablet-item"
                        @click.prevent="showBusiness"
                        :class="{'is-active': businessIsShowing == true}"
                    >
                        <h3 class="wh-areas-tablet-item-heading">
                            Business
                        </h3>
                        <i class="wh-areas-tablet-item-icon material-icons">
                            add
                        </i>
                        <div 
                            class="wh-areas-tablet-item-wrapper"
                        >
                            <div 
                                class="wh-areas-tablet-item-wrapper-image is-business"
                                :class="{'is-active': businessIsShowing == true}"
                            ></div>
                            <div 
                                class="wh-areas-tablet-item-wrapper-content"
                                :class="{'is-active': businessIsShowing == true}"
                            >
                                <p class="wh-areas-tablet-item-wrapper-content-text is-medium is-large">
                                    Set your sights and follow the path to success
                                </p>
                                <p class="wh-areas-tablet-item-wrapper-content-list is-large">
                                    Strategy, Interim, Media planning, Change Management, Education, Consulting, Project management, Analysis
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div class="wh-areas-items">
                    <div class="wh-areas-item">
                        <div class="wh-areas-item-image is-creative"></div>
                        <header class="wh-areas-item-header">
                            <h3 class="wh-areas-item-header-heading">
                                Creative
                            </h3>
                            <p class="wh-areas-item-header-text is-medium is-large">
                                Leave your imprint and tell your story to the world
                            </p>
                            <p class="wh-areas-item-header-list is-lead">
                                Idea and koncept, Design, Advertising, Marketing, Identity, Copy, Content, Illustration, Video, Animation, SoMe
                            </p>
                        </header>
                    </div>
                    <div class="wh-areas-item">
                        <div class="wh-areas-item-image is-tech"></div>
                        <header class="wh-areas-item-header">
                            <h3 class="wh-areas-item-header-heading">
                                Tech
                            </h3>
                            <p class="wh-areas-item-header-text is-medium is-large">
                                Create an experience, engage, and convert
                            </p>
                            <p class="wh-areas-item-header-list is-lead">
                                UX, UI, Web development, Software development, Education, Apps, Automation, SEO, SEM, Research
                            </p>
                        </header>
                    </div>
                    <div class="wh-areas-item">
                        <div class="wh-areas-item-image is-business"></div>
                        <header class="wh-areas-item-header">
                            <h3 class="wh-areas-item-header-heading">
                                Business
                            </h3>
                            <p class="wh-areas-item-header-text is-medium is-large">
                                Set your sights and follow the path to success
                            </p>
                            <p class="wh-areas-item-header-list is-lead">
                                Strategy, Interim, Media planning, Change Management, Education, Consulting, Project management, Analysis
                            </p>
                        </header>
                    </div>
                </div>
            </div>
        </div>

        <div class="wh-clients" data-transition-color="is-white">
            <div class="wh-clients-inner">
                <div class="wh-clients-header">
                    <div class="wh-clients-title">
                        <h2 class="wh-clients-title-item">
                            PEOPLE WE<br>
                            WORK WITH
                        </h2>
                    </div>
                    <div class="wh-clients-text">
                        <p class="wh-clients-text-item is-medium is-large">
                            From startups to larger listed companies in a variety of industries, our customers all share the same commitment and ambitious vision. We collaborate with them in a project-based manner and sometimes provide specialized, interim solutions. Ultimately, our goal is to work together to produce long-term results.
                        </p>
                    </div>
                </div>
                <div class="wh-clients-items">
                    <a 
                        href="https://colivia.com" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/colivia_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://greatsecurity.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/greatsecurity_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://hrmsoftware.se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/hrm_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.augment.se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/augment_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://wexiodisk.com" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/wexiodisk_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.nabsolutions.se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/nab_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://app-properties.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/app_properties_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://eurobatteryminerals.com" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/ebm_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.skanska.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/skanska_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://obos.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/obos_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.abena.se/sv-se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/abena_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.bolsius.com/se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/bolsius_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.castellum.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/castellum_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://el-vis.com" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/elinfo_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://www.stralfors.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/postnord_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://briox.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/briox_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    <a 
                        href="https://sun4energy.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/s4e_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                </div>
            </div>
        </div>

        <div class="wh-team" id="contact" data-transition-color="is-purple">
            <div class="wh-team-header">
                <div class="wh-team-title">
                    <h2 class="wh-team-title-item">
                        Don't be a<br>
                        stranger
                    </h2>
                </div>
                <div class="wh-team-text">
                    <p class="wh-team-text-item is-medium is-large">
                        Let’s have a first date! Feel free to reach out to any of us or simply shoot us an email at <a href="mailto:work@wellhello.se">work@wellhello.se</a>.
                    </p>
                </div>
            </div>
            
            <!--<a href="mailto:business@wellhello.se" class="wh-team-contact">
                <p class="wh-team-contact-text is-lead is-purple is-bold">
                    Släng iväg ett mejl
                </p>
            </a>-->

            <div class="wh-team-items">
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-daniel">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                GODFATHER OF STRATEGY & DESIGN
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Daniel is a passionate designer and advisor in brands, design and product development. He is an avid reader and listener of podcasts, ensuring his finger is always on the pulse of the industry. His insights and experiences often come in handy when he provides strategic expertise for our clients.
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <!--<p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Daniel Liljeblad
                        </p>-->
                        <p class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Godfather of strategy & design
                        </p>
                        <a href="tel:+46709500277" class="wh-team-item-header-link is-medium">
                            +4670 950 02 77
                        </a>
                        <a href="mailto:daniel@wellhello.se" class="wh-team-item-header-link is-medium">
                            daniel@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-martina">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                DESIGN & COMMUNICATION GODDESS
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Martina drives our customers' businesses forward with the help of effective communication and inspiring design. She gladly shares her expertise, both in the office and with our clients who hire her at the executive level. An unbeatable team member who consistently raises the bar in each project she undertakes.
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Martina Bengtsson
                        </p>
                        <p class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Design & communication goddess
                        </p>
                        <a href="tel:+46708337897" class="wh-team-item-header-link is-medium">
                            +4670 833 78 97
                        </a>
                        <a href="mailto:martina@wellhello.se" class="wh-team-item-header-link is-medium">
                            martina@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-anna">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                QUEEN OF CONTENT & PROJECT MANAGEMENT
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                With Anna's attention to detail, responsibility and knowledge, she plans, structures and ensures that our company's projects are completed on time and on budget. Furthermore, Anna is well versed in design and text production. She is the go-to person for creating visualizations of financial reports for many of our listed companies. 
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Anna Bergström
                        </p>
                        <p class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Queen of content & project management
                        </p>
                        <a href="tel:+46762203800" class="wh-team-item-header-link is-medium">
                            +4676 220 38 00
                        </a>
                        <a href="mailto:anna@wellhello.se" class="wh-team-item-header-link is-medium">
                            anna@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-emil">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                TECH OVERLORD
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Emil is the head of our tech projects and likes to geek out on new innovations – useful knowledge when developing digital products, building apps and getting different systems to talk to each other. If you come by the office, it is probably Emil who opens the door with a smile and makes you feel at home.
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Emil Makowski
                        </p>
                        <p class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Tech overlord
                        </p>
                        <a href="tel:+46763172530" class="wh-team-item-header-link is-medium">
                            +4676 317 25 30
                        </a>
                        <a href="mailto:emil@wellhello.se" class="wh-team-item-header-link is-medium">
                            emil@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-marcus">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                FRONTEND PIXELNINJA
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Marcus is a frontend developer with a passion for creating and maintaining websites and apps. His curiosity and eye for detail is a great asset when we develop and evaluate our web projects. Marcus is always willing to take on difficult challenges and go above and beyond to ensure that projects are completed to the highest standards. 
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Marcus Andersson
                        </p>
                        <p class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Frontend pixelninja
                        </p>
                        <a href="tel:+46761184857" class="wh-team-item-header-link is-medium">
                            +4676 118 48 57
                        </a>
                        <a href="mailto:marcus@wellhello.se" class="wh-team-item-header-link is-medium">
                            marcus@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-axel">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                DIGITAL MARKETING PROPHET
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Experienced, versatile and pragmatic. Three words to describe Axel. Many of his clients have stuck around for decades and we totally understand why. Axel knows all about digital campaigns that drive engagement and deliver results. He is also renowned for his home-baked treats that he often brings to the fika table.
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Axel Nilsson
                        </p>
                        <p class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            DIGITAL MARKETING PROPHET
                        </p>
                        <a href="tel:+46702674058" class="wh-team-item-header-link is-medium">
                            +4670 267 40 58
                        </a>
                        <a href="mailto:axel@wellhello.se" class="wh-team-item-header-link is-medium">
                            axel@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-tea">
                        <div class="wh-team-item-image-content">
                            <!--<h4 class="wh-team-item-image-content-title is-uppercase is-lead is-purple is-bold">
                                Word magician
                            </h4>-->
                            <p class="wh-team-item-image-content-text is-purple is-medium is-small">
                                Tea is the go-to person whenever you need an accurate and well-written text. She is highly skilled in producing social media content, web copywriting, and news articles that really captures the essence of the text, giving it life and making it stand out. Whether you need a few words, a longer piece or meticulous research, Tea is at your service.
                            </p>
                        </div>

                        <a 
                            href="" 
                            class="wh-team-item-image-toggle"
                        >
                            <span class="wh-team-item-image-toggle-icon material-icons">
                                add_circle_outline
                            </span>
                        </a>
                    </div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-pink is-medium">
                            Tea Juteström
                        </p>
                        <p class="wh-team-item-header-subtitle is-uppercase is-lead is-pink is-bold">
                            Word magician
                        </p>
                        <a href="tel:+46705089678" class="wh-team-item-header-link is-medium">
                            +4670 508 96 78
                        </a>
                        <a href="mailto:tea@wellhello.se" class="wh-team-item-header-link is-medium">
                            tea@wellhello.se
                        </a>
                    </header>
                </div>
            </div>
        </div>

        <sitefooter language="en" />
    </div>
</template>

<script>
import heroComponent from '../components/hero-component.vue';
import Sitefooter from '../components/layout/Sitefooter';
import { pageComposables } from '../composables/page-composables';

export default {
    name: 'startsida',

    metaInfo: {
        title: 'Well Hello – A design and communication agency in Växjö',
        meta: [
            {
                name: 'description',
                content: "We work daily to develop value-creating communication and experiences that help our customers strengthen their brand and achieve their business goals. With us, you get help from experts in strategy, design, content and tech. Let's go!",
            },
            {
                property: 'og:title',
                content: 'Well Hello – A design and communication agency in Växjö',
            },
            {
                property: 'og:description',
                content: "We work daily to develop value-creating communication and experiences that help our customers strengthen their brand and achieve their business goals. With us, you get help from experts in strategy, design, content and tech. Let's go!",
            },
            {
                property: 'og:url',
                content: 'https://wellhello.se/en',
            },
            {
                property: 'og:locale',
                content: 'en_GB',
            },
            {
                property: 'og:image',
                content: 'https://wellhello.se/wellhello-og.png',
            },
            {
                name: 'twitter:image',
                content: 'https://wellhello.se/wellhello-og.png',
            },
        ],
        link: [
            {
                rel: 'canonical',
                href: 'https://wellhello.se/en'
            },
        ],
        htmlAttrs: {
            lang: 'en-GB',
        }
    },

    components: {
        heroComponent,
        Sitefooter
    },

    data () {
        return {
            title: 'Startsida',
            creativeIsShowing: false,
            techIsShowing: false,
            businessIsShowing: false,
        }
    },

    mounted () {
        const { transitionModules } = pageComposables();
        transitionModules(
            '.wh-hero, .wh-areas, .wh-clients, .wh-team, .wh-sitefooter',
            {
                //'.wh-hero, .wh-hero-header, .wh-hero-text, .wh-areas'
                '.wh-hero': null,
                '.wh-hero-header': null,
                '.wh-hero-header-heading': null,
                '.wh-hero-text-item': {
                    delay: 0
                },
                '.wh-hero-spinning-link': null,
                '.wh-hero-spinning-link-icon': null,
                '.wh-hero-spinning-link-emoji': null,
                '.wh-areas': null,
                '.wh-sitefooter-item-logo': null
            }
        )

        $('.wh-team-item-image-toggle').on('click', function (event) {
            event.preventDefault()

            if ($(this).parent().hasClass('has-splash')) {
                $(this).parent().toggleClass('has-info-open')
                setTimeout(() => {
                    $(this).parent().toggleClass('has-splash')
                }, 50);   
            } else {
                $(this).parent().toggleClass('has-splash')
                setTimeout(() => {
                    $(this).parent().toggleClass('has-info-open')
                }, 300)
            }
        })
    },

    methods: {
        showCreative () {
            this.creativeIsShowing = !this.creativeIsShowing;
        },

        showTech () {
            this.techIsShowing = !this.techIsShowing;
        },

        showBusiness () {
            this.businessIsShowing = !this.businessIsShowing;
        }
    }
}

</script>
