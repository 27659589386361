<template>
    <div class="wh-view is-white">
        <hero 
            :isInverted="true"
            replacementWord="Vi stärker ditt finansiella varumärke" 
            data-transition-color="is-white"
        />

        <div class="wh-content" data-transition-color="is-white">
            <div class="wh-content-inner">
                <p class="wh-content-heading-text is-medium is-large is-purple is-thinner">
                    Vi är nyfikna på dig och vill veta mer om vilka utmaningar ditt bolag har. Kontakta oss för 15 minuters konsultation!
                </p>

                <a href="mailto:carl.forsstrom@beachfrontpr.se?subject=Finansiell kommunikation" class="wh-content-button wh-button is-primary is-blue">
                    Boka möte
                </a>

                <h3 class="wh-content-heading is-blue">
                    Syns ni på marknaden?
                </h3>

                <p class="wh-content-heading-text is-medium is-large is-purple">
                    Kommunikation med företagets målgrupper är som bekant avgörande för er som noterat bolag. Det skapar tydlighet mot aktieägare, attraherar nya investerare och skapar en långsiktigt gemensam bild av bolagets varumärke som investering.
                </p>
                
                <h3 class="wh-content-heading is-blue">
                    Kommunikation som märks
                </h3>

                <p class="wh-content-heading-text is-medium is-large is-purple">
                    Vi kan möta de flesta av era kommunikationsbehov oavsett om det handlar om att ta fram en equity story, utveckla er webb, hantera pressmeddelanden, designa finansiella rapporter, öka er synlighet i media, stärka er närvaro i sociala kanaler eller för den delen förbereda er för potentiella kriser. 
                </p>
            </div>
        </div>

        <div class="wh-content has-more-padding" data-transition-color="is-blue">
            <div class="wh-content-inner">
                <p class="wh-content-heading-text is-medium is-bigger-statement is-purple">
                    Vår utgångspunkt är alltid densamma – alla kan ha en unik story att berätta, vårt jobb är att identifiera, paketera och aktivera den i de kanaler som bäst stärker ert varumärke mot aktiemarknaden.
                </p>
            </div>
        </div>

        <div class="wh-clients" data-transition-color="is-white">
            <div class="wh-clients-inner">
                <div class="wh-clients-header">
                    <div class="wh-clients-title">
                        <h2 class="wh-clients-title-item is-blue is-smaller">
                            Erfarenheter
                        </h2>
                    </div>
                </div>
                <div class="wh-clients-items is-inverted">
                    <a 
                        href="https://eurobatteryminerals.com" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/ebm_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>
                    
                    <a 
                        href="https://www.unlimitedtravelgroup.se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/utg_vit.svg" alt="" class="wh-clients-item-logo is-inverted">
                    </a>

                    <a 
                        href="https://briox.se" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/briox_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>

                    <a 
                        href="https://niutech.se/"
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/niutech_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>

                    <a 
                        href="https://levelbio.se/en" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/levelbio_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>

                    <a 
                        href="https://www.northgoldab.com/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/northgold_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>

                    <a 
                        href="https://www.augment.se/" 
                        target="_blank"
                        class="wh-clients-item"
                    >
                        <img src="../assets/static/images/clients/augment_vit.svg" alt="" class="wh-clients-item-logo">
                    </a>               
                </div>
            </div>
        </div>

        <div class="wh-team" id="contact" data-transition-color="is-white">
            <div class="wh-team-header">
                <div class="wh-team-title">
                    <h2 class="wh-team-title-item is-smaller is-blue">
                        Hör av dig
                    </h2>
                </div>
                <div class="wh-team-text">
                    <p class="wh-team-text-item is-medium is-large is-purple">
                        Vi är ett seniort kommunikationsteam med stor erfarenhet av att hjälpa noterade bolag med kommunikation. Vårt förhållningssätt är att det ska vara enkelt att få hjälp med att lyckas nå ut. Därför erbjuder vi en modell med ett litet dynamiskt team som gör lösningen kostnadseffektiv och utan fördyrande overhead.
                    </p>
                </div>
            </div>
            

            <div class="wh-team-items">
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-anna"></div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-purple is-medium">
                            Anna Bergström
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-purple is-bold">
                            Well Hello
                        </h5>
                        <p class="wh-team-item-header-title is-purple is-medium has-padding-top is-smallest">
                            Art director och content creator
                        </p>
                        <a href="tel:0762203800" class="wh-team-item-header-link is-purple is-medium is-smallest">
                            076 220 38 00
                        </a>
                        <a href="mailto:anna@wellhello.se" class="wh-team-item-header-link is-purple is-medium is-smallest">
                            anna@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-emil"></div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-purple is-medium">
                            Emil Makowski
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-purple is-bold">
                            Well Hello
                        </h5>
                        <p class="wh-team-item-header-title is-purple is-medium has-padding-top is-smallest">
                            Digital specialist
                        </p>
                        <a href="tel:0763172530" class="wh-team-item-header-link is-purple is-medium is-smallest">
                            076 317 25 30
                        </a>
                        <a href="mailto:emil@wellhello.se" class="wh-team-item-header-link is-purple is-medium is-smallest">
                            emil@wellhello.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-carl"></div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-purple is-medium">
                            Carl Forsström
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-purple is-bold">
                            BEACHFRONT COMMUNICATIONS
                        </h5>
                        <p class="wh-team-item-header-title is-purple is-medium has-padding-top is-smallest">
                            PR- och mediaspecialist
                        </p>
                        <a href="tel:0708330210" class="wh-team-item-header-link is-purple is-medium is-smallest">
                            070 833 02 10
                        </a>
                        <a href="mailto:carl.forsstrom@beachfrontpr.se" class="wh-team-item-header-link is-purple is-medium is-smallest">
                            carl.forsstrom@beachfrontpr.se
                        </a>
                    </header>
                </div>
                <div class="wh-team-item">
                    <div class="wh-team-item-image is-sara"></div>
                    <header class="wh-team-item-header">
                        <p class="wh-team-item-header-title is-uppercase is-lead is-purple is-medium">
                            Sara Möndell
                        </p>
                        <h5 class="wh-team-item-header-subtitle is-uppercase is-lead is-purple is-bold">
                            BEACHFRONT COMMUNICATIONS
                        </h5>
                        <p class="wh-team-item-header-title is-purple is-medium has-padding-top is-smallest">
                            PR-strateg och kriskommunikation
                        </p>
                        <a href="tel:0709355326" class="wh-team-item-header-link is-purple is-medium is-smallest">
                            070 935 53 26
                        </a>
                        <a href="mailto:sara.mondell@beachfrontpr.se" class="wh-team-item-header-link is-purple is-medium is-smallest">
                            sara.mondell@beachfrontpr.se
                        </a>
                    </header>
                </div>
            </div>
        </div>

        <sitefooter language="sv" />
    </div>
</template>

<script>

import Hero from '../components/hero-component.vue';
import Sitefooter from '../components/layout/Sitefooter';
import { pageComposables } from '../composables/page-composables';

export default {
    name: 'ux-ui-designer',

    components: {
        Hero,
        Sitefooter,
    },

    metaInfo: {
        title: 'Finansiell kommunikation som stärker ert varumärke',
        meta: [
            {
                name: 'description',
                content: 'Vi hjälper noterade bolag med finansiell kommunikation som ökar er synlighet hos aktieägare och investerare. Boka 15 min konsultation!',
            },
            {
                property: 'og:title',
                content: 'Finansiell kommunikation som stärker ert varumärke',
            },
            {
                property: 'og:description',
                content: "Vi hjälper noterade bolag med finansiell kommunikation som ökar er synlighet hos aktieägare och investerare. Boka 15 min konsultation!",
            },
            {
                property: 'og:url',
                content: 'https://wellhello.se/finansiell-kommunikation',
            },
            {
                property: 'og:locale',
                content: 'sv_SE',
            },
            {
                property: 'og:image',
                content: 'https://wellhello.se/wh-bf-og-image.jpg',
            },
            {
                name: 'twitter:image',
                content: 'https://wellhello.se/wh-bf-og-image.jpg',
            },
        ],
        link: [
            {
                rel: 'canonical',
                href: 'https://wellhello.se/finansiell-kommunikation'
            },
        ],
        htmlAttrs: {
            lang: 'sv-SE'
        }
    },

    data () {
        return {
            title: 'Finansiell kommunikation',
        }
    },

    mounted () {
        const { transitionModules } = pageComposables();
        transitionModules(
            '.wh-hero, .wh-content, .wh-team, .wh-clients, .wh-sitefooter, .wh-team',
            {
                //'.wh-hero, .wh-hero-header, .wh-hero-text, .wh-areas'
                '.wh-hero': null,
                '.wh-hero-header': null,
                '.wh-hero-header-heading': null,
                '.wh-sitefooter-item-logo': null
            }
        )

        $('.wh-team-item-image-toggle').on('click', function (event) {
            event.preventDefault()

            if ($(this).parent().hasClass('has-splash')) {
                $(this).parent().toggleClass('has-info-open')
                setTimeout(() => {
                    $(this).parent().toggleClass('has-splash')
                }, 50);   
            } else {
                $(this).parent().toggleClass('has-splash')
                setTimeout(() => {
                    $(this).parent().toggleClass('has-info-open')
                }, 300)
            }
        })
    },

    methods: {
    }
}

</script>