/**
*
*  @author     Emil Makowski <emil@wellhello.se>
*  @author     Tommy Borggren <tommy@wellhello.se>
*  @copyright  Copyright © Well Hello Design & Communication AB
*/

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------

import jQuery from 'jquery';

(function ($) {

    //--------------------------------------------------------------------------
    // Private properties
    //--------------------------------------------------------------------------

    //--------------------------------------------------------------------------
    // Constructor
    //--------------------------------------------------------------------------

    function init () {
        initAddEventListeners();
    }

    //--------------------------------------------------------------------------
    // Private methods
    //--------------------------------------------------------------------------


    function initAddEventListeners() {
        $(document).on('click', '.wh-sitefooter-cc', (event) => {
            event.preventDefault();
            openCkyModal();
        });
    }

    function openCkyModal() {
        $('.cky-modal').addClass('cky-modal-open');
        $('.cky-consent-container').addClass('cky-hide');
        $('.cky-overlay.cky-hide').removeClass('cky-hide');
    }


    //--------------------------------------------------------------------------
    // Bootstrap
    //--------------------------------------------------------------------------

    $(document).ready(function () {

        if (document.getElementsByClassName('wh-sitefooter')[0]) {
            init();
        }
    })

})(jQuery);
