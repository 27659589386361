/**
*
*  @author     Emil Makowski <emil@wellhello.se>
*  @author     Tommy Borggren <tommy@wellhello.se>
*  @copyright  Copyright © Well Hello Design & Communication AB
*/

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------

import jQuery from 'jquery';

//--------------------------------------------------------------------------
// Module
//--------------------------------------------------------------------------

(function ($) {

    //--------------------------------------------------------------------------
	// Private properties
	//--------------------------------------------------------------------------

    //--------------------------------------------------------------------------
	// Constructor
	//--------------------------------------------------------------------------

    function init () {
        initRefs();
        initAddEventListeners();
    }

    //--------------------------------------------------------------------------
    // Private methods
    //--------------------------------------------------------------------------

    /**
     *
     */
    function initRefs () {

    }

    function initAddEventListeners () {
        $(document).on('click', '.sfe', (event) => {
            event.preventDefault();
        });
    }


    //--------------------------------------------------------------------------
	// Bootstrap
	//--------------------------------------------------------------------------

    $(document).ready(function () {
        if (document.getElementsByClassName('wh-areas')[0]) {
            init();
        }
    });

})(jQuery);
