import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';

window.$ = require('jquery');

//require('@/assets/js/core/scroller')
require('@/assets/js/modules/wh-cky')
require('@/assets/js/modules/wh-areas-tablet')
//require('@/assets/js/modules/wh-hero')
//require('@/assets/js/modules/wh-storyline')


require("@/assets/scss/app.scss")

Vue.config.productionTip = false

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

//Vue.use(VueGtag, {
//    bootstrap: localStorage.getItem('whAcceptedCookies') == 'true' ? true : false,
//    config: {
//        id: 'G-BTC5C3Q176'
//    }
//}, router)

new Vue({
    router,
    render: h => h(App),
    mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')