<template>
    <div class="wh-view is-pink">
        <hero 
            :isInverted="true"
            replacementWord="UX/UI Designer" 
        />

        <div class="wh-integritypolicy has-no-padding-top">
            <div class="wh-integritypolicy-inner">

                <h3 class="wh-integritypolicy-heading is-purple">
                    Påverka, förändra och inspirera som vår nya UX/UI-designer
                </h3>

                <p class="wh-integritypolicy-text-heading is-medium is-large is-purple">
                    Som UX/UI-designer hos oss kommer du att genomföra designprojekt från början till slut i spännande och varierande uppdrag. Dina arbetsuppgifter kan anpassas beroende på vilken inriktning du har men kan bland annat innefatta:
                </p>
                
                <h4 class="wh-integritypolicy-text-title is-bold is-large is-purple has-small-margin-bottom">
                    Inriktning UI-designer
                </h4>
                <p class="wh-integritypolicy-text is-medium is-purple">
                    <ul class="wh-integritypolicy-text-list">
                        <li>
                            Förstå våra kunders krav och översätta dem till konkreta designlösningar
                        </li>
                        <li>
                            Utforska och tillämpa de senaste trenderna och teknikerna inom UI-design
                        </li>
                        <li>
                            Skapa wireframes, prototyper och visuellt tilltalande UI-design
                        </li>
                        <li>
                            Bidra till utvecklingen av vårt erbjudande inom tech
                        </li>
                    </ul>
                </p>
                
                <h4 class="wh-integritypolicy-text-title is-bold is-large is-purple has-small-margin-bottom">
                    Inriktning UX-designer
                </h4>
                <p class="wh-integritypolicy-text is-medium is-purple">
                    <ul class="wh-integritypolicy-text-list">
                        <li>
                            Utforska användarbehov och mål genom research och användartester
                        </li>
                        <li>
                            Implementera bästa praxis för användbarhet, tillgänglighet och interaktionsdesign
                        </li>
                        <li>
                            Utforska och tillämpa de senaste trenderna och teknikerna inom UX-design
                        </li>
                        <li>
                            Arbeta tätt tillsammans med kollegor, kunder och användare för en sammanhängande användarupplevelse
                        </li>
                        <li>
                            Skapa wireframes och prototyper
                        </li>
                        <li>
                            Bidra till utvecklingen av vårt erbjudande inom tech
                        </li>
                    </ul>
                </p>
                
                <h4 class="wh-integritypolicy-text-title is-bold is-large is-purple has-small-margin-bottom">
                    Vi ser gärna att du har
                </h4>
                <p class="wh-integritypolicy-text is-medium is-purple">
                    <ul class="wh-integritypolicy-text-list">
                        <li>
                            Minst 3 års erfarenhet av UX/UI-design på byrå, inhouse eller liknande
                        </li>
                        <li>
                            Koll på relevanta designverktyg som Sketch, XD och Figma 
                        </li>
                        <li>
                            God förståelse för användarcentrerad design
                        </li>
                        <li>
                            Lätt att kommunicera och samarbeta
                        </li>
                        <li>
                            Förmåga att hantera flera projekt samtidigt och leverera resultat i tid
                        </li>
                        <li>
                            Kreativt tänkande
                        </li>
                    </ul>
                </p>
                
                <h4 class="wh-integritypolicy-text-title is-bold is-large is-purple has-small-margin-bottom">
                    Vi erbjuder
                </h4>
                <p class="wh-integritypolicy-text is-medium is-purple">
                    <ul class="wh-integritypolicy-text-list">
                        <li>
                            En inspirerande arbetsmiljö där du kan växa och utvecklas i din roll som UX/UI-designer
                        </li>
                        <li>
                            Stor frihet, flexibilitet och möjlighet att påverka ditt arbete och dina uppdrag
                        </li>
                        <li>
                            Spännande projekt och samarbeten med olika kunder i flera branscher
                        </li>
                        <li>
                            Ett stöttande team med bra sammanhållning
                        </li>
                    </ul>
                </p>
                
                <p class="wh-integritypolicy-text is-medium is-purple">
                    <br><br>
                    Well Hello är en design- och kommunikationsbyrå i Växjö som dagligen skapar digitala upplevelser, formulerar pricksäkert content och trollar med pixlar. Hos oss får du chansen att arbeta både direkt mot våra kunder och tätt tillsammans med kollegor.
                    <br><br>
                    Är du redo att ta dig an utmaningen så skicka ditt CV, portfolio och ett personligt brev där du beskriver ditt intresse för tjänsten och varför du skulle vara en bra kandidat till <a class="is-purple is-bold" href="mailto:daniel@wellhello.se">daniel@wellhello.se</a>
                </p>

                <h4 class="wh-integritypolicy-text-title is-bold is-large is-purple has-small-margin-bottom">
                    Vi hörs!
                </h4>
            </div>
        </div>

        <sitefooter language="sv" />
    </div>
</template>

<script>

import Hero from '../components/hero-component.vue';
import Sitefooter from '../components/layout/Sitefooter';
import { pageComposables } from '../composables/page-composables';

export default {
    name: 'ux-ui-designer',

    components: {
        Hero,
        Sitefooter,
    },

    metaInfo: {
        title: 'Well Hello – Söker UX/UI designer',
        meta: [
            {
                name: 'description',
                content: 'Påverka, förändra och inspirera som vår nya UX/UI-designer',
            },
            {
                property: 'og:title',
                content: 'Well Hello – Söker UX/UI designer',
            },
            {
                property: 'og:description',
                content: "Påverka, förändra och inspirera som vår nya UX/UI-designer",
            },
            {
                property: 'og:url',
                content: 'https://wellhello.se/ux-ui-designer',
            },
            {
                property: 'og:locale',
                content: 'sv_SE',
            },
            {
                property: 'og:image',
                content: 'https://wellhello.se/wellhello-og.png',
            },
            {
                name: 'twitter:image',
                content: 'https://wellhello.se/wellhello-og.png',
            },
        ],
        link: [
            {
                rel: 'canonical',
                href: 'https://wellhello.se/ux-ui-designer'
            },
        ],
        htmlAttrs: {
            lang: 'sv-SE'
        }
    },

    data () {
        return {
            title: 'UX/UI designer',
        }
    },

    mounted () {
        const { transitionModules } = pageComposables();
        transitionModules(
            '.wh-hero, .wh-integritypolicy, .wh-sitefooter',
            {
                //'.wh-hero, .wh-hero-header, .wh-hero-text, .wh-areas'
                '.wh-hero': null,
                '.wh-hero-header': null,
                '.wh-hero-header-heading': null,
                '.wh-sitefooter-item-logo': null
            }
        )

        $('.wh-team-item-image-toggle').on('click', function (event) {
            event.preventDefault()

            if ($(this).parent().hasClass('has-splash')) {
                $(this).parent().toggleClass('has-info-open')
                setTimeout(() => {
                    $(this).parent().toggleClass('has-splash')
                }, 50);   
            } else {
                $(this).parent().toggleClass('has-splash')
                setTimeout(() => {
                    $(this).parent().toggleClass('has-info-open')
                }, 300)
            }
        })
    },

    methods: {
    }
}

</script>